import * as React from "react"
import { Link, HeadFC } from "gatsby"
import Layout from "../../components/layout/layout"
import GetThePrices from "../../components/gettheprices/gettheprices"
import NewsletterSignup from "../../components/newslettersignup/newslettersignup"
import Tag from "../../components/tag/tag"
import { StaticImage } from "gatsby-plugin-image"
import ReactMarkdown from 'react-markdown'

import Button from "../button/button"

import plus from "../../images/plusicon.svg"

import phone from "../../images/phone.svg"
import email from "../../images/email.svg"
import chat from "../../images/chat.svg"
import "./faq.scss"
import strings from "../../i18n"
import Img from "gatsby-image"
import { navigate } from 'gatsby';

type FAQProps = {
};
type FAQState = {
    isOpen: boolean;
    mounted: boolean;
};

class FAQ extends React.Component<FAQProps, FAQState> {
    state: FAQState = {
      isOpen: false,
      mounted: false
    };

    componentDidMount() {
      this.setState({mounted: true})
    }

  render(){
  return (
    <div id="faq" className="faq section">
      <div className="container">
          <Tag type="yellow">{strings.need_help}</Tag>
          <h1>{strings.frequently_asked_questions}</h1>
          <h4>{strings.faq_pitch}</h4>
          <div className="faq-content section">
            <div className="questions">
              <div className="question">
                <div className="title body1" onClick={(e) => e.target.parentElement.classList.toggle("open")}>{strings.faq_question1}<img src={plus} onClick={(e) => e.target.parentElement.parentElement.classList.toggle("open")}/></div>
                <div id="faqquestion1" className="body1 answer"><ReactMarkdown>{strings.faq_answer1}</ReactMarkdown></div>
              </div>
              <div className="question">
                <div className="title body1" onClick={(e) => e.target.parentElement.classList.toggle("open")}>{strings.faq_question2}<img src={plus} onClick={(e) => e.target.parentElement.parentElement.classList.toggle("open")}/></div>
                <div id="faqquestion2" className="body1 answer"><ReactMarkdown>{strings.faq_answer2}</ReactMarkdown></div>
              </div>
              <div className="question">
                <div className="title body1" onClick={(e) => e.target.parentElement.classList.toggle("open")}>{strings.faq_question3}<img src={plus} onClick={(e) => e.target.parentElement.parentElement.classList.toggle("open")}/></div>
                <div id="faqquestion3" className="body1 answer"><ReactMarkdown>{strings.faq_answer3}</ReactMarkdown></div>
              </div>
              <div className="question">
                <div className="title body1" onClick={(e) => e.target.parentElement.classList.toggle("open")}>{strings.faq_question4}<img src={plus} onClick={(e) => e.target.parentElement.parentElement.classList.toggle("open")}/></div>
                <div id="faqquestion4" className="body1 answer"><ReactMarkdown>{strings.faq_answer4}</ReactMarkdown></div>
              </div>
              <div className="question">
                <div className="title body1" onClick={(e) => e.target.parentElement.classList.toggle("open")}>{strings.faq_question5}<img src={plus} onClick={(e) => e.target.parentElement.parentElement.classList.toggle("open")}/></div>
                <div id="faqquestion5" className="body1 answer"><ReactMarkdown>{strings.faq_answer5}</ReactMarkdown></div>
              </div>
              <div className="question">
                <div className="title body1" onClick={(e) => e.target.parentElement.classList.toggle("open")}>{strings.faq_question6}<img src={plus} onClick={(e) => e.target.parentElement.parentElement.classList.toggle("open")}/></div>
                <div id="faqquestion6" className="body1 answer"><ReactMarkdown>{strings.faq_answer6}</ReactMarkdown></div>
              </div>
              <div className="question">
                <div className="title body1" onClick={(e) => e.target.parentElement.classList.toggle("open")}>{strings.faq_question7}<img src={plus} onClick={(e) => e.target.parentElement.parentElement.classList.toggle("open")}/></div>
                <div id="faqquestion7" className="body1 answer"><ReactMarkdown>{strings.faq_answer7}</ReactMarkdown></div>
              </div>
            </div>
            <h4 className="mobile-only mq-header">{strings.more_questions}</h4>
            <div className="more-questions">
              <div className="info">
              <div className="copy">
              <h4 className="desktop-only">{strings.more_questions}</h4>
              <h4 className="mobile-only">{strings.talk_to_home_specialist}</h4>
              <div className="body1">{strings.get_your_questions_answered}</div>
              </div>
              <StaticImage objectFit="contain" src="../../images/HomeSpecialist.png" className="home-specialist" alt={strings.get_your_questions_answered}/>
              </div>
              <div className="buttons">
              <Button onClick={() => {this.setState({isOpen: true})}} size="small" iconFirst={true}><img src={phone}/>{strings.schedule_call}</Button>
              <Button onClick={() => {LO.messenger.open()}} size="small" iconFirst={true} outline={true}><img src={chat}/>{strings.chat_online}</Button>
              <Button path="/contact" size="small" outline={true} iconFirst={true}><img src={email}/>{strings.email}</Button>
              </div>
            </div>
          </div>
        </div>
    </div>
  )
  }
}

export default FAQ