import * as React from "react"
import { Link, HeadFC } from "gatsby"
import Layout from "../components/layout/layout"
import SEO from "../components/seo/seo"
import GetThePrices from "../components/gettheprices/gettheprices"
import Tag from "../components/tag/tag"
import strings from "../i18n"
import FAQ from "../components/faq/faq"
import CTA from "../components/cta/cta"

const FAQPage = () => {
  return (
    <Layout chat={true}>      
        <FAQ/>
        <CTA 
          primary={strings.cta_primary}
          secondary={strings.cta_secondary}
          description={strings.formatString(strings.cta_primary_description, strings.reservation_slots_number)}
          button={strings.cta_button_primary}
          to="/access"
        />
    </Layout>
  )
}

export default FAQPage

export const Head: HeadFC = () => <SEO title="FAQ"/>
